import React from "react";
import { Link } from "gatsby";
import { Online, Offline } from "react-detect-offline";

import LogoInvert from "../atoms/LogoInvert";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBook, faNotesMedical, faUserCircle, faExclamationTriangle, faBan } from '@fortawesome/free-solid-svg-icons';

import "./Footer.scss";

const Footer = () => {

  return (
    <footer>
      <LogoInvert link={true} classes="" />
      <nav>
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={ faHome } size={ 'lg' } />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/using-this-guide">
              <FontAwesomeIcon icon={ faBook } size={ 'lg' } />
              <span>Using this guide</span>
            </Link>
          </li>
          <li>
            <Link to="/safety-at-the-crags">
              <FontAwesomeIcon icon={ faNotesMedical } size={ 'lg' } />
              <span>Safety at the crags</span>
            </Link>
          </li>
          <li>
            <Link to="/disclaimer">
              <FontAwesomeIcon icon={ faExclamationTriangle } size={ 'lg' } />
              <span>Disclaimer</span>
            </Link>
          </li>
          <Online>
            <li>
              <Link to="/profile">
                <FontAwesomeIcon icon={ faUserCircle } size={ 'lg' } />
                <span>Account</span>
              </Link>
            </li>
          </Online>
          <Offline>
            <li>
              <Link disabled className="disabled">
                <FontAwesomeIcon icon={ faBan } size={ 'lg' } />
                <small>Offline</small>
              </Link>
            </li>
          </Offline>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer;