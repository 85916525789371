import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Symbol from "../components/atoms/Symbol";
import Sun from "../components/atoms/Sun";
import Footer from "../components/organisms/Footer";

const UsingThisGuidePage = (props) => {

  const information = props.data.allMarkdownRemark.edges[0].node.html;

  return (
    <>
      <Seo title="Using this Guide" />
      <Header classes="header--hasLogo">
        <Logo link={true} classes="" />
      </Header>
      <main className="static main--hasLogo">
        <div className="container">
          <div className="information" dangerouslySetInnerHTML={{ __html: information }} />
          <ul className="symbol-list symbol-list--all">
            <li key="1"><Symbol name="Sym-Approach-Abseil" /><small>Approach abseil</small></li>
            <li key="2"><Symbol name="Sym-Approach-Downhill" /><small>Approach downhill</small></li>
            <li key="3"><Symbol name="Sym-Approach-Flattish" /><small>Approach flattish</small></li>
            <li key="4"><Symbol name="Sym-Approach-Scramble" /><small>Approach scramble</small></li>
            <li key="5"><Symbol name="Sym-Approach-Uphill" /><small>Approach uphill</small></li>
            <li key="6"><Symbol name="Sym-Base-Worthy-Of-A-Picnic" /><small>Base worthy of a picnic</small></li>
            <li key="7"><Symbol name="Sym-Can-Climb-In-Rain" /><small>Can climb in rain</small></li>
            <li key="8"><Symbol name="Sym-Can-Get-Greasy-Depending-On-Conditions" /><small>Can get greasy depending on conditions</small></li>
            <li key="9"><Symbol name="Sym-Endurance-Routes" /><small>Endurance routes</small></li>
            <li key="10"><Symbol name="Sym-Good-Base-For-Children" /><small>Good base for children</small></li>
            <li key="11"><Symbol name="Sym-Helmet-Recommended" /><small>Helmet recommended</small></li>
            <li key="12"><Symbol name="Sym-Over-Hanging" /><small>Overhanging</small></li>
            <li key="13"><Symbol name="Sym-Power-Routes" /><small>Power routes</small></li>
            <li key="14"><Symbol name="Sym-Roofy" /><small>Roofy</small></li>
            <li key="15"><Symbol name="Sym-Seeps-After-Rain" /><small>Seeps after rain</small></li>
            <li key="16"><Symbol name="Sym-Slabby" /><small>Slabby</small></li>
            <li key="17"><Symbol name="Sym-Steep-Or-Uneven-Base" /><small>Steep or uneven base</small></li>
            <li key="18"><Symbol name="Sym-Technical-Routes" /><small>Technical routes</small></li>
            <li key="19"><Symbol name="Sym-Trad-Route" /><small>Trad route</small></li>
            <li key="20"><Symbol name="Sym-Vertical" /><small>Vertical</small></li>
            <li key="21" id={`svg-sun-21`}>
              <Symbol name="Sym-Sun" />
              <Sun start={true} slots={[100]} sunId={`sun-21`} /><small>Sun most of the day</small>
            </li>
            <li key="22" id={`svg-sun-22`}>
              <Symbol name="Sym-Sun" /><Sun start={false} slots={[100]} sunId={`sun-22`} /><small>Shade most of the day</small>
            </li>
            <li key="23" id={`svg-sun-23`}>
              <Symbol name="Sym-Sun" />
              <Sun start={true} slots={[50, 100]} sunId={`sun-23`} /><small>Morning sun and afternoon shade</small>
            </li>
            <li key="24" id={`svg-sun-24`}>
              <Symbol name="Sym-Sun" />
              <Sun start={false} slots={[50, 100]} sunId={`sun-24`} /><small>Morning shade and afternoon sun</small>
            </li>
            <li key="25" id={`svg-sun-25`}>
              <Symbol name="Sym-Sun" />
              <Sun start={false} slots={[75, 100]} sunId={`sun-25`} /><small>Late afternoon sun</small>
            </li>
            <li key="26" id={`svg-sun-26`}>
              <Symbol name="Sym-Sun" />
              <Sun start={true} slots={[75, 100]} sunId={`sun-26`} /><small>Late afternoon shade</small>
            </li>
            <li key="27" id={`svg-sun-27`}>
              <Symbol name="Sym-Sun" />
              <Sun start={true} slots={[25, 100]} sunId={`sun-27`} /><small>Early morning sun</small>
            </li>
            <li key="28" id={`svg-sun-28`}>
              <Symbol name="Sym-Sun" />
              <Sun start={false} slots={[25, 100]} sunId={`sun-28`} /><small>Early morning shade</small>
            </li>
            <li key="29" id={`svg-sun-29`}>
              <Symbol name="Sym-Sun" />
              <Sun start={false} slots={[30, 70, 100]} sunId={`sun-29`} /><small>Early morning and late afternoon shade</small>
            </li>
            <li key="30"><Symbol name="Sym-Arf" /><small>ARF (Anchor Replacement Fund)</small></li>
            <li key="31"><Symbol name="Sym-Anchors" /><small>Bolted anchors</small></li>
            <li key="32"><Symbol name="Sym-Abseil" /><small>Abseils</small></li>
            <li key="33"><Symbol name="Sym-Stance" /><small>Stances on multi-pitch sport routes</small></li>
            <li key="34"><Symbol name="Sym-Stance-Trad" /><small>Stances on multi-pitch trad routes</small></li>
            <li key="35"><Symbol name="Sym-Multipitch" /><small>Multi-pitch route</small></li>
            <li key="36">
              <svg width="100%" height="18">
                <path d="M0,4,100,4Z" fill="transparent" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0,4,100,4Z" fill="transparent" stroke="#FFF200" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 8" />
                <path d="M0,16,100,16Z" fill="transparent" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <small>Sport route</small>
            </li>
            <li key="37">
              <svg width="100%" height="18">
                <path d="M0,4,100,4Z" fill="transparent" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M0,4,100,4Z" fill="transparent" stroke="#ED1C24" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 8" />
                <path d="M0,16,100,16Z" fill="transparent" stroke="#ED1C24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <small>Trad route</small>
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query UsingGuideQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/using-this-guide"}}}) {
      edges {
        node {
          frontmatter {
            path
          }
          html
        }
      }
    }
  }
`;

export default UsingThisGuidePage;
