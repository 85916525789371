import React from "react";
import loadable from "@loadable/component";
import { Link } from "gatsby";

import './LogoInvert.scss';

const LogoInvert = ({ link, classes }) => {

  let Ra = loadable(() => import('../../assets/rockapp-vertical-invert.inline.svg'));

  return (
    <div className={`logo ` + classes}>
      {link ? (
        <Link to="/">
          <Ra />
        </Link>
      ) : (
        <Ra />
      )}
    </div>
  )
}

export default LogoInvert;