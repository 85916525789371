import React from "react";
import loadable from "@loadable/component";
import { Link } from "gatsby";

import './Logo.scss';

const Logo = ({ link, classes }) => {

  // let Bmp = loadable(() => import('../../assets/blue-mountain.inline.svg'));
  // let Ra = loadable(() => import('../../assets/rockapp-horizontal.inline.svg'));
  let Ra = loadable(() => import('../../assets/rockapp-vertical.inline.svg'));

  return (
    <div className={`logo ` + classes}>
      {link ? (
        <Link to="/">
          <Ra />
        </Link>
      ) : (
        <Ra />
      )}
      {/* <Link to="/">
        <Bmp />
        <Ra />
        <small>Blue Mountain Publishers</small>
      </Link> */}
    </div>
  )
}

export default Logo;