import React from "react";
import loadable from "@loadable/component";

const Symbol = ({name}) => {

  let Icon;

  switch (name) {
    case "Sym-Approach-Abseil":
      Icon = loadable(() => import('../../assets/icons/symbols/approach-abseil.inline.svg'));
      break;
    case "Sym-Approach-Downhill":
      Icon = loadable(() => import('../../assets/icons/symbols/approach-downhill.inline.svg'));
      break;
    case "Sym-Approach-Flattish":
      Icon = loadable(() => import('../../assets/icons/symbols/approach-flattish.inline.svg'));
      break;
    case "Sym-Approach-Scramble":
      Icon = loadable(() => import('../../assets/icons/symbols/approach-scramble.inline.svg'));
      break;
    case "Sym-Approach-Uphill":
      Icon = loadable(() => import('../../assets/icons/symbols/approach-uphill.inline.svg'));
      break;
    case "Sym-Base-Worthy-Of-A-Picnic":
      Icon = loadable(() => import('../../assets/icons/symbols/base-worthy-of-a-picnic.inline.svg'));
      break;
    case "Sym-Can-Climb-In-Rain":
      Icon = loadable(() => import('../../assets/icons/symbols/can-climb-in-rain.inline.svg'));
      break;
    case "Sym-Can-Get-Greasy-Depending-On-Conditions":
      Icon = loadable(() => import('../../assets/icons/symbols/can-get-greasy-depending-on-conditions.inline.svg'));
      break;
    case "Sym-Endurance-Routes":
      Icon = loadable(() => import('../../assets/icons/symbols/endurance-routes.inline.svg'));
      break;
    case "Sym-Good-Base-For-Children":
      Icon = loadable(() => import('../../assets/icons/symbols/good-base-for-children.inline.svg'));
      break;
    case "Sym-Helmet-Recommended":
      Icon = loadable(() => import('../../assets/icons/symbols/helmet-recommended.inline.svg'));
      break;
    case "Sym-Over-Hanging":
      Icon = loadable(() => import('../../assets/icons/symbols/over-hanging.inline.svg'));
      break;
    case "Sym-Power-Routes":
      Icon = loadable(() => import('../../assets/icons/symbols/power-routes.inline.svg'));
      break;
    case "Sym-Roofy":
      Icon = loadable(() => import('../../assets/icons/symbols/roofy.inline.svg'));
      break;
    case "Sym-Seeps-After-Rain":
      Icon = loadable(() => import('../../assets/icons/symbols/seeps-after-rain.inline.svg'));
      break;
    case "Sym-Slabby":
      Icon = loadable(() => import('../../assets/icons/symbols/slabby.inline.svg'));
      break;
    case "Sym-Steep-Or-Uneven-Base":
      Icon = loadable(() => import('../../assets/icons/symbols/steep-or-uneven-base.inline.svg'));
      break;
    case "Sym-Technical-Routes":
      Icon = loadable(() => import('../../assets/icons/symbols/technical-routes.inline.svg'));
      break;
    case "Sym-Trad-Route":
      Icon = loadable(() => import('../../assets/icons/symbols/trad-route.inline.svg'));
      break;
    case "Sym-Vertical":
      Icon = loadable(() => import('../../assets/icons/symbols/vertical.inline.svg'));
      break;
    case "Sym-Arf":
      Icon = loadable(() => import('../../assets/icons/symbols/arf.inline.svg'));
      break;
    case "Sym-Anchors":
      Icon = loadable(() => import('../../assets/icons/symbols/anchors.inline.svg'));
      break;
    case "Sym-Abseil":
      Icon = loadable(() => import('../../assets/icons/symbols/abseil.inline.svg'));
      break;
    case "Sym-Stance":
      Icon = loadable(() => import('../../assets/icons/symbols/stance.inline.svg'));
      break;
    case "Sym-Stance-Trad":
      Icon = loadable(() => import('../../assets/icons/symbols/stance-trad.inline.svg'));
      break;
    case "Sym-Multipitch":
      Icon = loadable(() => import('../../assets/icons/symbols/multipitch.inline.svg'));
      break;
    case "Sym-Sun":
      Icon = loadable(() => import('../../assets/icons/symbols/sun.inline.svg'));
      break;
    default:
      Icon = null;
  }

  return (
    <>
      <Icon />
    </>
  );

};


export default Symbol;