import React from "react";

import "./Sun.scss";

const Sun = ({start, slots}) => {

  return (
    <div className={`sun-circle sun-circle--` + (start ? 'sun' : 'shade')}>
      {slots.map((slot, index) => {
        return (
          <span key={index} style={{ width: index === 0 ? slot+'%' : index === slots.length - 1 ? (100 - slots[index - 1]+'%') : ((slots[index + 1] - slots[index - 1]+'%')) }}></span>
        )
      })}
    </div>
  )
}

export default Sun;
        